import com.soywiz.kgl.KmlGlDummy.finish
import com.soywiz.klock.*
import com.soywiz.korev.Key
import com.soywiz.korge.*
import com.soywiz.korge.tween.*
import com.soywiz.korge.view.*
import com.soywiz.korge.view.tween.show
import com.soywiz.korim.color.*
import com.soywiz.korim.font.readBitmapFont
import com.soywiz.korim.format.*
import com.soywiz.korim.text.TextAlignment
import com.soywiz.korio.file.std.*
import com.soywiz.korma.geom.*
import com.soywiz.korma.geom.vector.roundRect
import com.soywiz.korma.interpolation.*
import com.soywiz.korui.UiApplication
import com.soywiz.korui.UiTextField
import com.soywiz.korui.native.NativeUiFactory
import com.soywiz.korev.*
import com.soywiz.korge.*
import com.soywiz.korge.baseview.BaseView
import com.soywiz.korge.component.Component
import com.soywiz.korge.html.*
import com.soywiz.korge.input.*
import com.soywiz.korge.view.*
import com.soywiz.korim.color.*
import com.soywiz.korim.font.*
import com.soywiz.korim.format.*
import com.soywiz.korio.file.std.*
import com.soywiz.korma.geom.*
import com.soywiz.korma.geom.vector.*
import kotlin.properties.*
import kotlin.random.*






suspend fun main() = Korge(width = 480, height = 640, title = "Schneckedde's Translator", bgcolor = RGBA(253, 247, 240)) {
	// TODO: we will write code for our game here later
	val text_font = resourcesVfs["clear_sans.fnt"].readBitmapFont()
	var btnSize = 500.0
	var text = "Hallo Papa"






	val restartBlock = container {
		val background = roundRect(btnSize, btnSize, 5.0, fill = RGBA(185, 174, 160))


		onClick {
			finish()
			println("d")

		}

	}
	var myText = text(text, 40.0, RGBA(100,100,100)){
		centerXOn(restartBlock)
		centerYOn(restartBlock)
	}
}
